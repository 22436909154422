<template>
 <div class="container">
    <div class="forgot-password" >
    <span style="margin-bottom:10px">Forgot Password</span> 
    <div class="mail-content">
      
      <input type="email" v-model="forgetMail" placeholder="Email*" />
      </div>
    <div class="rest-log">
      <router-link tag="a" to="/login"> <span> ←</span> Log In</router-link>
      <button @click="forgetPassword"> NEXT</button>
    </div>
  </div>
 </div>
 


</template>

<script>
import gql from "graphql-tag";
export default {
  data() {
    return {
      forgetMail: "",
    };
  },
  methods: {
    forgetPassword() {
      this.$apollo.mutate({
        mutation: gql`
          mutation forgetPassword($email: String!) {
            forgetPassword(email: $email) {
              errors {
                message
              }
              message
            }
          }
        `,
        variables: {
          email: this.forgetMail,
        },
      }).then(response=>{console.log(response)})

      this.$router.push({name:'verify-code', query:{kind:'reset_password'}})
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}
.container{
  width: 100%;
  height: 93vh;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}
input{
    margin-bottom: 22px;
  height: 55px;
  border-top: unset;
  border-right: unset;
  border-left: unset;
  border-bottom: 1.6px solid #717276;
  background-color: #66666626;
  padding-left: 15px;
  border-radius: 5px 5px 0 0;
  width: 276px;
}
input:focus{ 
  outline: none;
  border-bottom: 2px solid #27c26c;

}
.mail-content{
      width: 450px;
    display: flex;
    
    justify-content: center;
}
.forgot-password{
      position: absolute;
    border: 1px solid;
    width: 450px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.rest-log{
  width: 450px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 39px;
    align-items: center;
}
.rest-log>button{
  height: 45px;
    border-top: unset;
    border-right: unset;
    border-left: unset;
    border-bottom: unset;
    background-color: #27c26c;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    cursor: pointer;
}
</style>